<script setup lang="ts">
const chatStore = useChatStore();
</script>
<template>
  <div class="hero min-h-screen bg-base-200">
    <div class="hero-content text-center">
      <div class="max-w-xl">
        <h1 class="text-3xl sm:text-5xl font-bold">Ciao sono Abby!</h1>
        <h3 class="text-md sm:text-xl text-justify py-6">
          <p class="py-4">
            In questa demo Abby è stato addestrato per rispondere a domande generiche e di
            puro carattere esemplificativo.
          </p>
          <p class="py-4">
            Puoi chiedere quello che vuoi ma, per una conversazione più efficace che
            mostri le potenzialità, ti consigliamo di preferire domande relative al
            contesto della demo.
          </p>
          <p>Buona chiacchierata!</p>
        </h3>
        <!-- <div>{{ chatStore.agents }}</div> -->
        <!-- <button
          v-for="agent in chatStore.agents"
          class="btn bg-abaco-300 text-white mr-2 text-2xl"
          @click="goto(agent.name)"
        >
          {{ agent.name }}
        </button> -->
        <NuxtLink
          v-for="agent in chatStore.agents"
          :to="{ name: 'chat', query: { agentName: agent.name } }"
          class="btn bg-abaco-300 text-white m-2 text-sm sm:text-2xl"
        >
          {{ agent.name }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
